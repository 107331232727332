import * as React from "react"
import Layout from "../../../components/layout"
import { Helmet } from "react-helmet"
import { Seo } from "../../../components/seo"
import { Breadcrumb, Card, Col, Collapse, Row, Space, Typography } from "antd"
import PageContent from "../../../components/page-content"
import { graphql, Link } from "gatsby"
import { JsonDebugger } from "../../../components/json-debugger"
import { RichText } from "prismic-reactjs"
import { htmlSerializer } from "../../../utils/html-serializer"
import { navigate } from "gatsby"
import { spacer, categoryCard, faq } from "./faq.module.less"
const { Title } = Typography
const { Panel } = Collapse
interface Faq {
  title: {
    text: string
  }
  faq_answer: {
    richText: unknown[]
  }
}
interface FaqNode {
  node: {
    data: Faq
  }
}
const FAQPage = ({
  data,
}: {
  data: { allPrismicFaq: { group: { edges: FaqNode }[] } }
}) => {
  if (!data) return null
  const categories = data.allPrismicFaq.group.reduce((p, c) => {
    const {
      uid,
      url,
      document: {
        data: { title },
      },
    } = c.edges[0].node.data.category
    p.push({
      category: { uid, title, url, totalCount: c.totalCount },
      faqs: c.edges.map(({ node: { data, url, uid } }) => ({
        url,
        uid,
        ...data,
      })),
    })
    return p
  }, [])
  const categoryCards = categories.map(({ category, faqs }) => {
    const moreTitle =
      category.totalCount > faqs.length
        ? `View ${category.totalCount - faqs.length} more`
        : "View All"
    return (
      <Col key={category.uid} span={24} lg={12} className={faq}>
        <Card
          className={categoryCard}
          title={<Link to={category.url}>{category.title}</Link>}
          //   extra={
          //     <Link key="more" to={category.url}>
          //       More
          //     </Link>
          //   }
          actions={[
            <Link key="more" to={category.url}>
              {moreTitle}
            </Link>,
          ]}
        >
          <Collapse
            activeKey={0}
            onChange={(key) => {
              const url = key.pop()
              //   console.log({ key, url })
              if (url) {
                navigate(url)
              }
            }}
          >
            {faqs.map(({ title, faq_answer, uid, url }, i) => {
              return (
                <Panel
                  header={
                    <Link to={`${category.url}#${uid}`}>{title.text}</Link>
                  }
                  key={`${category.url}#${uid}`}
                >
                  {/* <RichText
                    render={faq_answer.richText}
                    htmlSerializer={htmlSerializer}
                  /> */}
                </Panel>
              )
            })}
          </Collapse>
          {/* {category.totalCount} articles in this category */}
        </Card>
      </Col>
    )
  })
  return (
    <Layout>
      <Seo title={"FAQs"} />
      <PageContent>
        <Space direction="vertical" size="large" className={spacer}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/learn/">Learn</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>FAQ</Breadcrumb.Item>
          </Breadcrumb>
          <Title>FAQs</Title>
          <Row gutter={[24, 24]}>{categoryCards}</Row>
          {/* <JsonDebugger json={{ categories, data }} /> */}
        </Space>
      </PageContent>
    </Layout>
  )
}

export const query = graphql`
  query FAQPageQuery {
    allPrismicFaq(sort: { fields: data___faq_sort }) {
      group(field: data___category___id, limit: 3) {
        edges {
          node {
            uid
            url
            data {
              title {
                text
              }
              category {
                uid
                url
                document {
                  ... on PrismicFaqCategory {
                    data {
                      title
                    }
                  }
                }
              }
              faq_answer {
                richText
              }
              related {
                document {
                  uid
                }
              }
            }
          }
        }
        totalCount
      }
    }
  }
`

export default FAQPage
